import { yup } from "@driscollsinc/style-guide-web2.0";
import { DRISCOLLS_EMAIL } from "../../constants/userManagementConstants";

export const NewCombinationSetupSchema = yup.object().shape({
    Warehouse: yup.array().required('Warehouse is required'),
    Email: yup
    .string()
    .test(
      'is-driscolls-email',
      'Please enter a valid Driscolls email!',
      (value) => value?.endsWith(DRISCOLLS_EMAIL) || value?.indexOf('@') === -1,
    )
});