import { http, HttpResponse } from 'msw';
import { urls } from '../demea/api';
import pointMatrixMock from './data/pointMatrix.json';
import pointMatrixResultSet from './data/pointMatrixResultSet.json';

const baseApiAddress = window.config.API_BASE_ADDRESS;

export const handlers = [
    http.get(`${baseApiAddress}${urls.POINT_MATRIX}`, () => {
        // return HttpResponse.json(pointMatrixMock);
        return null;
    }),
    http.post(`${baseApiAddress}${urls.POINT_MATRIX}`, async ({ request }) => {
        const info = await request.json();
        console.info('pointMatrix post => ', info);

        // if (info.Operation === 'Preview') {
        //     return HttpResponse.json(pointMatrixResultSet);
        // } else {
        //     return HttpResponse.json(info);
        // }

        return null;
    }),
    http.post(`${baseApiAddress}${urls.ADD_USER_MANAGEMENT}`, async ({ request }) => {
        const info = await request.json();
        console.info('user management post => ', info);
        return null;

    })
];
