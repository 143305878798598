import {
    ApplicationPageTitle,
    ApplicationPage,
    DriscollsTranslatedValue,
    DriscollsVContainer,
    DriscollsHContainer,
    DriscollsButton
} from '@driscollsinc/style-guide-web2.0';

import styles from './index.module.scss';
import { NAMESPACE } from '../../../i18n';
import UserManagementGrid from '../../components/userManagement/userManagementGrid';
import { useEffect, useState } from 'react';
import APIEndPoints from '../../api';
import { useEagerHttp, useHttp } from '../../hooks/useHttp';
import { useApplicationContext } from '../../hooks/useApplicationContext';
import useDebounce from '../../hooks/useDebounce';
import { TUserManagementList } from '../../types/userManagement';
import { useNavigate } from 'react-router-dom';
import { routesName } from '../../routes/routes';
import { WarehouseResponse } from '../../components/userManagement/warehouse/Types';
import { convertToStringArr } from '../../../utils/format';
import { USER_MANAGEMENT_CONSTANTS } from '../../constants/userManagementConstants';
import { UserManagementPost } from './Types';

const UserManagement = () => {
    const navigate = useNavigate();
    const [data, setData] = useState<TUserManagementList>();
    const [page, setPage] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);
    const [emailFilter, setEmailFilter] = useState<string>('');
    const [createdByFilter, setCreatedByFilter] = useState<string>('');
    const { data: warehouseList } = useEagerHttp<WarehouseResponse>(APIEndPoints.GET_WAREHOUSES());
    const [filterOptions, setFilterOptions] = useState<{ warehouse: string[] }>();
    const [warehouseFilter, setWarehouseFilter] = useState<string>();
    const itemsPerPage = 10;
    const { postData } = useHttp<UserManagementPost>(APIEndPoints.USER_MANAGEMENT_POST());
    const { showSuccess, showError } = useApplicationContext();

    const { getData } = useHttp<TUserManagementList>(
        APIEndPoints.USER_MANAGEMENT_GET(page, itemsPerPage, warehouseFilter, emailFilter, createdByFilter)
    );

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await getData();
            setData(response);
        } catch (error) {
            console.log(error.message);
            showError(error);
        } finally {
            setLoading(false);
        }
    };

    const resetFilters = () => {
        setEmailFilter('');
        setCreatedByFilter('');
        setWarehouseFilter('');
    };

    useEffect(() => {
        if (warehouseList && warehouseList.Data) {
            setFilterOptions({
                warehouse: convertToStringArr(warehouseList.Data, USER_MANAGEMENT_CONSTANTS.WAREHOUSE_NBR)
            });
        }
    }, [warehouseList]);

    const onFilterChange = (filters) => {
        resetFilters();
        filters.forEach((filter) => {
            switch (filter.id) {
                case USER_MANAGEMENT_CONSTANTS.LOCAL_QA_MANAGER_EMAIL:
                    if (filter.value !== emailFilter) {
                        setPage(1);
                    }
                    setEmailFilter(filter.value);
                    break;
                case USER_MANAGEMENT_CONSTANTS.CREATED_BY:
                    if (filter.value !== createdByFilter) {
                        setPage(1);
                    }
                    setCreatedByFilter(filter.value);
                    break;
                case USER_MANAGEMENT_CONSTANTS.WAREHOUSE_NBR:
                    if (filter.value !== warehouseFilter) {
                        setPage(1);
                    }
                    setWarehouseFilter(filter.value);
                    break;
                default:
                    break;
            }
        });
    };

    const debouncedFetchData = useDebounce(fetchData, 500);

    useEffect(() => {
        debouncedFetchData();
    }, [page, warehouseFilter, emailFilter, createdByFilter]);

    const goToAddUser = () => {
        navigate(routesName.USER_MANAGEMENT_ADD);
    };

    const onDelete = async (row) => {
        setLoading(true);
        const payload = {
            [USER_MANAGEMENT_CONSTANTS.WAREHOUSE_NBR]: [row.WarehouseNbr],
            [USER_MANAGEMENT_CONSTANTS.LOCAL_QA_MANAGER_EMAIL]: row.LocalQAManagerEmailAddress,
            [USER_MANAGEMENT_CONSTANTS.BUSINESS_UNIT]: USER_MANAGEMENT_CONSTANTS.DEMEA,
            [USER_MANAGEMENT_CONSTANTS.CREATED_BY]: row.CreatedBy,
            [USER_MANAGEMENT_CONSTANTS.MODIFIED_BY]: row.ModifiedBy,
            [USER_MANAGEMENT_CONSTANTS.IS_DELETED]: true
        } as UserManagementPost;
        try {
            await postData(payload);
            await fetchData();
            showSuccess('Assigned warehouse deleted successfully');
        } catch (error) {
            showError(error?.message || 'Something went wrong!');
        } finally {
            setLoading(false);
        }
    };

    return (
        <ApplicationPage className={styles.qmpApplicationPage}>
            <ApplicationPageTitle
                title={{ text: 'User Management', nameSpace: NAMESPACE.COMMON }}
                description={{ text: 'Manage the access of QA Managers', nameSpace: NAMESPACE.USER_MANAGEMENT }}
            />
            <DriscollsVContainer>
                <DriscollsHContainer className={styles.rightAlign} noPadding>
                    <DriscollsButton
                        className={styles.btnLink}
                        label={<DriscollsTranslatedValue value="Add" nameSpacing={NAMESPACE.COMMON} />}
                        onClick={() => goToAddUser()}
                    />
                </DriscollsHContainer>
            </DriscollsVContainer>
            <UserManagementGrid
                data={data?.Data || []}
                selectedPage={page}
                totalItem={Number(data?.TotalResults) || 0}
                onPageChanged={(page) => setPage(page)}
                loading={loading}
                itemPerPage={itemsPerPage}
                filterOptions={filterOptions}
                onDelete={(row: UserManagementPost) => {
                    onDelete(row);
                }}
                onFilterChange={onFilterChange}
            />
        </ApplicationPage>
    );
};

export default UserManagement;
