import { FC, memo } from 'react';
import { DriscollsSelect, DriscollsTranslatedValue } from '@driscollsinc/style-guide-web2.0';
import { transformArrayToLabelValue } from '../../../../utils/format';
import { NAMESPACE } from '../../../../i18n';
import { useEagerHttp } from '../../../hooks/useHttp';
import APIEndPoints from '../../../api';
import { WarehouseResponse } from './Types';
import { USER_MANAGEMENT_CONSTANTS } from '../../../constants/userManagementConstants';
interface WarehouseSelectProps {
    styles: any;
    onChange?: (selectedOption: any) => void;
    error?: any;
    defaultValue?: any;
}

const WarehouseSelect: FC<WarehouseSelectProps> = memo(function WarehouseSelect({ styles, ...rest }) {
    const { data } = useEagerHttp<WarehouseResponse>(APIEndPoints.GET_WAREHOUSES());

    return (
        <DriscollsSelect
            name="warehouse"
            className={styles.selectField}
            containerClassName={styles.selectContainer}
            isMulti={true}
            options={transformArrayToLabelValue(data?.Data, USER_MANAGEMENT_CONSTANTS.WAREHOUSE_NBR, USER_MANAGEMENT_CONSTANTS.WAREHOUSE_NBR)}
            placeholder={<DriscollsTranslatedValue nameSpacing={NAMESPACE.USER_MANAGEMENT} value="Warehouse" />}
            {...rest}
        />
    );
});

export default WarehouseSelect;
