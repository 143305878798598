export const USER_MANAGEMENT_CONSTANTS = {
    WAREHOUSE_NBR: 'WarehouseNbr',
    LOCAL_QA_MANAGER_EMAIL: 'LocalQAManagerEmailAddress',
    MODIFIED_BY: 'ModifiedBy',
    MODIFIED_DATE_TIME: 'ModifiedDateTime',
    WAREHOUSE: 'Warehouse',
    EMAIL: 'Email',
    BUSINESS_UNIT: 'BusinessUnit',
    CREATED_BY: 'CreatedBy',
    CREATED_DATE_TIME: 'CreatedDateTime',
    IS_DELETED: 'IsDeleted',
    DEMEA: 'DEMEA'
};

export const KEY_MAPPING = {
    WarehouseNbr: 'Warehouse',
    LocalQAManagerEmailAddress: 'Email',
    CreatedDateTime: 'Created Date',
    CreatedBy: 'Created By'
};

export const DRISCOLLS_EMAIL = '@driscolls.com';
