import { DirectionEnum, DriscollsComponentSwitcher, DriscollsDataTable, DriscollsMobileList, useLang } from '@driscollsinc/style-guide-web2.0';
import { DuDateUtilities } from '../../../../legacy/DriscollsReactUtilities';

import { KEY_MAPPING, USER_MANAGEMENT_CONSTANTS } from '../../../constants/userManagementConstants';
import { TUserManagement } from '../../../types/userManagement';
import { NAMESPACE } from '../../../../i18n';
import { useGetStaticData } from '../../../hooks/useGetStaticData';
import { DriscollsTrashButton } from '@driscollsinc/style-guide-web2.0';
import { UserManagementPost } from '../../../pages/UserManagement/Types';

export interface IUserManagementGridFilterOptions {
    warehouse: string[];
}

interface IUserManagementGrid {
    loading: boolean;
    data: TUserManagement[];
    totalItem: number;
    itemPerPage: number;
    onPageChanged: (pageNumber: number) => void;
    selectedPage: number;
    filterOptions?: IUserManagementGridFilterOptions;
    onFilterChange: (filters: { id: string; value: string }[]) => void;
    onDelete: (id: UserManagementPost) => void;
}

const UserManagementGrid = (props: IUserManagementGrid) => {
    const { loading, data, totalItem, itemPerPage, onPageChanged, selectedPage, filterOptions, onFilterChange, onDelete } = props;
    const { getTextTranslated } = useLang([NAMESPACE.USER_MANAGEMENT]);
    const { transformData } = useGetStaticData();
    const formatData = (data: TUserManagement[]) => data.map((row) => (
        {
            ...row,
            [USER_MANAGEMENT_CONSTANTS.CREATED_DATE_TIME]: DuDateUtilities.ToPrettyDateTime(row[USER_MANAGEMENT_CONSTANTS.CREATED_DATE_TIME])
        }));

    const columns = [
        {
            id: 'action',
            size: 45,
            enablePinning: true,
            header: () => null,
            cell: ({ row }: any) => {
                return (
                    <>
                        <DriscollsTrashButton onClick={() => onDelete(row?.original)} />
                    </>
                );
            }
        },
        {
            id: USER_MANAGEMENT_CONSTANTS.WAREHOUSE_NBR,
            accessorKey: USER_MANAGEMENT_CONSTANTS.WAREHOUSE_NBR,
            header: getTextTranslated('Warehouse', NAMESPACE.COMMON),
            enableColumnFilter: true,
            enablePinning: false,
            selectFilterOptions: filterOptions?.warehouse
        },
        {
            id: USER_MANAGEMENT_CONSTANTS.LOCAL_QA_MANAGER_EMAIL,
            accessorKey: USER_MANAGEMENT_CONSTANTS.LOCAL_QA_MANAGER_EMAIL,
            header: getTextTranslated('Email', NAMESPACE.COMMON),
            enableColumnFilter: true,
            enablePinning: false
        },
        {
            id: USER_MANAGEMENT_CONSTANTS.CREATED_BY,
            accessorKey: USER_MANAGEMENT_CONSTANTS.CREATED_BY,
            header: getTextTranslated('Created By', NAMESPACE.COMMON),
            enableColumnFilter: true,
            enablePinning: false
        },
        {
            id: USER_MANAGEMENT_CONSTANTS.CREATED_DATE_TIME,
            accessorKey: USER_MANAGEMENT_CONSTANTS.CREATED_DATE_TIME,
            header: getTextTranslated('Created Date', NAMESPACE.COMMON),
            enableColumnFilter: false,
            enablePinning: false
        }
    ];

    const mobileListData = (data) => {
        const formattedData = formatData(data);
        const transformedData = formattedData.map((item) => transformData(item, KEY_MAPPING));
        return transformedData;
    };

    return (
        <DriscollsComponentSwitcher
            direction={DirectionEnum.width}
            componentList={[
                {
                    breakPoint: 799,
                    component: (
                        <DriscollsMobileList
                            isLoading={loading}
                            data={mobileListData(data)}
                            selectedPage={selectedPage}
                            onPageChange={onPageChanged}
                            itemsPerPage={itemPerPage}
                            totalItems={totalItem}
                            hideKeys={[USER_MANAGEMENT_CONSTANTS.MODIFIED_BY, USER_MANAGEMENT_CONSTANTS.MODIFIED_DATE_TIME]}
                            paginationText={`Total No of Records: ${totalItem}`}
                        />
                    )
                },
                {
                    breakPoint: 800,
                    component: (
                        <DriscollsDataTable
                            isLoading={loading}
                            manualPagination={true}
                            selectedPage={selectedPage}
                            columns={columns}
                            showHover={false}
                            data={formatData(data)}
                            onPageChange={onPageChanged}
                            itemsPerPage={itemPerPage}
                            totalItems={totalItem}
                            textFilterPlaceholder={false}
                            enableDragAndDrop={false}
                            showColumnVisibility={false}
                            paginationText={`Total No of Records: ${totalItem}`}
                            showDownloadButton={false}
                            noRowMessage={'No records available'}
                            enableServerSideFiltering={true}
                            onFilterChange={onFilterChange}
                        />
                    )
                }
            ]}
        />
    );
};

export default UserManagementGrid;
