export const convertLabelValueObj = <T extends string>(arr: T[], label: string, key: string) => {
    const formattedArr = arr.map((item) => ({
        label: item[label],
        value: item[key]
    }));

    return formattedArr;
};

export const convertToLabelValue = <T extends string>(arr: T[]): { label: T; value: T }[] => {
    const formattedArr = arr.map((item) => ({
        label: item,
        value: item
    }));

    return formattedArr;
};

export const mapOptions = (options, key) => options?.map((item) => item[key]) || [];

export const transformArrayToLabelValue = (arr, labelKey, valueKey) => {
    let formattedArr=[];
    if(arr){
        formattedArr = arr.map(item => ({
            label: item[labelKey],
            value: item[valueKey]
        }))
    }
return formattedArr;
}

export const convertToStringArr = (arr, stringValue) => {
return arr.map(item => item[stringValue]);
}